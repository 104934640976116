import React, { useState } from 'react'
import { PageMaxWidth } from '../util-components/util-components'
import Panel from '../panel/panel'
import LeaguesTable from './leagues-table/leagues-table'
import { useControlledQuery } from '../table-controls/table-controls'
import { meshGatewayClient } from '../../apollo/client'
import { GET_LEAGUES } from './leagues-queries'
import { GetLeagues, GetLeaguesVariables } from '../../graphql-types/GetLeagues'
import LeaguesFilters, { AvailableFilters, DateFilter } from './leagues-filters'
import { SectionDistrictEmptyFilter } from '../tournament-filters/tournament-filters'

export interface LeaguesPaginationProps extends GetLeaguesVariables {
  offset: number
  limit: number
}
const LeaguesListing = () => {
  const [section, setSection] = useState<string>()
  const [district, setDistrict] = useState<string>()
  const [dateRange, setDateRange] = useState<DateFilter>({ from: new Date(), to: new Date() })

  const getFilters = () => {
    let filters: Record<any, any> = {}
    if (district && district !== SectionDistrictEmptyFilter.ALL) {
      filters = { ...filters, parentOrganisationId: district }
    }
    if (section && section !== SectionDistrictEmptyFilter.ALL) {
      filters = { ...filters, parentOrganisationId: section }
    }
    if (dateRange.from) {
      filters = { ...filters, startDate: { gte: dateRange.from.toISOString() } }
    }
    if (dateRange.to) {
      filters = { ...filters, endDate: { lt: dateRange.to.toISOString() } }
    }
    return filters
  }

  const { data: areaLeagues, loading, controlProps } = useControlledQuery<
    GetLeagues,
    LeaguesPaginationProps
  >(GET_LEAGUES, {
    client: meshGatewayClient,
    getTotalItems: (area: GetLeagues) => area.leagues.totalItems ?? 0,
    notifyOnNetworkStatusChange: true,
    transformVariables: (v: LeaguesPaginationProps) => {
      const { offset, limit } = v
      const filters = getFilters()

      return {
        ...(Object.keys(filters).length > 0 && { filter: filters }),
        pagination: { limit, skip: offset }
      }
    }
  })

  return (
    <PageMaxWidth>
      <Panel>
        <LeaguesFilters
          excludedFilters={[
            AvailableFilters.SEARCH,
            AvailableFilters.CATEGORY,
            AvailableFilters.STATUS
          ]}
          onFilterChange={filters => {
            if (filters.date) {
              setDateRange(filters.date)
            }
            setSection(filters.section)
            setDistrict(filters.district)
          }}
        />
      </Panel>
      <Panel extendedPadding>
        <LeaguesTable
          leagues={areaLeagues?.leagues?.items}
          controlProps={controlProps}
          loading={loading}
        />
      </Panel>
    </PageMaxWidth>
  )
}

export default LeaguesListing
