// extracted by mini-css-extract-plugin
export var bodyBase = "leagues-module--body-base--X4VFo leagues-module--site-font--ETM4A";
export var bodyLarge = "leagues-module--body-large--Hv+nv leagues-module--body-base--X4VFo leagues-module--site-font--ETM4A";
export var bodyLargeBold = "leagues-module--body-large-bold--zkBTF leagues-module--body-base--X4VFo leagues-module--site-font--ETM4A";
export var bodyRegular = "leagues-module--body-regular--9OQ1D leagues-module--body-base--X4VFo leagues-module--site-font--ETM4A";
export var bodyRegularBold = "leagues-module--body-regular-bold--2898N leagues-module--body-base--X4VFo leagues-module--site-font--ETM4A";
export var bodySmall = "leagues-module--body-small--oyPSw leagues-module--body-base--X4VFo leagues-module--site-font--ETM4A";
export var bodySmallBold = "leagues-module--body-small-bold--BSm+g leagues-module--body-base--X4VFo leagues-module--site-font--ETM4A";
export var borderTop = "leagues-module--border-top--yDhMD";
export var breakWordContainer = "leagues-module--break-word-container--HQniy";
export var buttonIconBase = "leagues-module--button-icon-base--7A+gC";
export var clickLink = "leagues-module--click-link--mRyDX";
export var dropdownBase = "leagues-module--dropdown-base--v1g6t";
export var dropdownSelectBase = "leagues-module--dropdown-select-base--jfqvL leagues-module--text-input--OcbWR";
export var flexCol = "leagues-module--flex-col--oBSHK";
export var formErrorMessage = "leagues-module--form-error-message--gZfBl";
export var h3 = "leagues-module--h3--Z0l+D";
export var h4 = "leagues-module--h4--ccXVL";
export var hoverLink = "leagues-module--hover-link--TXS6H";
export var hoverRow = "leagues-module--hover-row--Iy3Y7";
export var inputField = "leagues-module--input-field--3c7I7";
export var membershipContainer = "leagues-module--membership-container--0XMXi leagues-module--flex-col--oBSHK leagues-module--primary-border--WMoph";
export var membershipHeader = "leagues-module--membership-header--Q1QHu";
export var membershipHeading = "leagues-module--membership-heading--5Rtk2";
export var membershipLabel = "leagues-module--membership-label--FDJe9";
export var moreFiltersBorderClass = "leagues-module--more-filters-border-class--fb6jk";
export var pageBg = "leagues-module--page-bg--Ltrte";
export var pointer = "leagues-module--pointer--CVmGK";
export var primaryBorder = "leagues-module--primary-border--WMoph";
export var searchWrapper = "leagues-module--searchWrapper--0vWBS";
export var shadowBoxLight = "leagues-module--shadow-box-light--4otsv";
export var siteFont = "leagues-module--site-font--ETM4A";
export var slideDownAndFade = "leagues-module--slideDownAndFade--+6BFC";
export var slideLeftAndFade = "leagues-module--slideLeftAndFade--kPaM9";
export var slideRightAndFade = "leagues-module--slideRightAndFade---zI02";
export var slideUpAndFade = "leagues-module--slideUpAndFade--FhPjt";
export var statusDecoration = "leagues-module--status-decoration--HH4m4";
export var textInput = "leagues-module--text-input--OcbWR";
export var textInverted = "leagues-module--text-inverted--aQLKY";
export var textMediumDark = "leagues-module--text-medium-dark--MPdcm";
export var tooltipFont = "leagues-module--tooltipFont--hbNa1";
export var unstyledButton = "leagues-module--unstyled-button--VsESx";