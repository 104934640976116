import SEO from '../../components/seo'
import React from 'react'
import Layout from '../../components/layout/layout'
import LeaguesListing from '../../components/leagues/leagues-listing'

const LeaguesPage = () => {
  return (
    <Layout>
      <SEO title="Leagues" />
      <LeaguesListing />
    </Layout>
  )
}
export default LeaguesPage
