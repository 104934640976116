import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import DropdownCustom from '../dropdown-custom/dropdown-custom'
import Dropdown, { Option } from 'src/components/dropdown/dropdown'
import { PlayerCategory } from '../../graphql-types/globalUstaTypes'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import TextInput from '../input/input'
import * as styles from './leagues.module.less'
import { useOrgLevel } from '../../utils/auth'
import Button from '../button/button'
import LeaguesMoreFilters from './leagues-more-filters'
import { useDefaultSectionAndDistrict } from '../../utils/helper/useDefaultSectionAndDistrict'
import { useDebounce } from '../../hooks/use-debounce'

export type DateFilter = { from: Date; to: Date }
export enum AvailableFilters {
  SEARCH = 'search',
  DATE = 'date',
  STATUS = 'status',
  CATEGORY = 'category',
  SECTION = 'section',
  DISTRICT = 'district'
}
export type LeagueFilters = {
  search?: string
  date?: DateFilter
  category?: PlayerCategory | ''
  status?: string
  section?: string
  district?: string
}

type AreaLeaguesFiltersProps = {
  onFilterChange: (filters: LeagueFilters) => void
  excludedFilters?: AvailableFilters[]
}
const LeaguesFilters: FC<AreaLeaguesFiltersProps> = ({ onFilterChange, excludedFilters = [] }) => {
  const { t } = useTranslation()
  const { isNational, isSection } = useOrgLevel()
  const [moreFiltersVisible, setMoreFiltersVisible] = useState(false)
  const [dateRange, setDateRange] = useState<DateFilter>({ from: new Date(), to: new Date() })
  const [category, setCategory] = useState<PlayerCategory | ''>('')
  const [status, setStatus] = useState<string>('')
  const [search, setSearch] = useState<string>('')
  const [section, setSection] = useState<string>()
  const [district, setDistrict] = useState<string>()
  const searchFilter = useDebounce(search, 500)

  const categoryOptions: Option[] = useMemo(() => {
    return [
      { value: '', label: t('any category') },
      { value: PlayerCategory.Junior, label: t('junior') },
      { value: PlayerCategory.Adult, label: t('adult') },
      { value: PlayerCategory.Wheelchair, label: t('wheelchair') }
    ]
  }, [t])

  const statusOptions: Option[] = useMemo(() => {
    return [
      { value: '', label: t('any status') },
      { value: 'pending', label: t('pending') },
      { value: 'approved', label: t('approved') },
      { value: 'rejected', label: t('rejected') }
    ]
  }, [t])

  const shouldDisplay = useCallback(
    (filterType: AvailableFilters) => excludedFilters.every(filter => filter !== filterType),
    [excludedFilters]
  )

  const defaultSectionDistrict = useDefaultSectionAndDistrict()
  useEffect(() => {
    if (defaultSectionDistrict) {
      const { defaultDistrict, defaultSection } = defaultSectionDistrict
      setSection(defaultSection)
      setDistrict(defaultDistrict)
    }
  }, [defaultSectionDistrict])

  useEffect(() => {
    const leagueFilters = {
      ...(shouldDisplay(AvailableFilters.SEARCH) && { search: searchFilter }),
      ...(shouldDisplay(AvailableFilters.DATE) && { date: dateRange }),
      ...(shouldDisplay(AvailableFilters.CATEGORY) && { category }),
      ...(shouldDisplay(AvailableFilters.STATUS) && { status }),
      ...(shouldDisplay(AvailableFilters.SECTION) && { section }),
      ...(shouldDisplay(AvailableFilters.DISTRICT) && { district })
    }
    onFilterChange(leagueFilters)
  }, [category, dateRange, district, onFilterChange, searchFilter, section, shouldDisplay, status])

  const setDateFilters = (dates: DateFilter) => {
    setDateRange({
      from: new Date(dates.from.setHours(0, 0, 0)),
      to: new Date(dates.to.setHours(23, 59, 59))
    })
  }

  return (
    <Grid container wrap={'wrap'}>
      {shouldDisplay(AvailableFilters.SEARCH) && (
        <div className={styles.searchWrapper}>
          <TextInput
            placeholder={t('search league or id')}
            value={search}
            onChange={t => setSearch(t?.target.value)}
            disableUnderline
            outlined
            fullWidth
            onClearClick={search ? () => setSearch('') : undefined}
          />
        </div>
      )}
      {shouldDisplay(AvailableFilters.DATE) && (
        <DropdownCustom
          from={dateRange.from}
          setFilters={setDateFilters}
          to={dateRange.to}
          initFilter={dateRange}
        />
      )}
      {shouldDisplay(AvailableFilters.CATEGORY) && (
        <Dropdown
          dropdownClassname={styles.inputField}
          options={categoryOptions}
          selected={category}
          onSelect={option => setCategory(option.value)}
        />
      )}
      {shouldDisplay(AvailableFilters.STATUS) && (
        <Dropdown
          dropdownClassname={styles.inputField}
          options={statusOptions}
          selected={status}
          onSelect={option => setStatus(option.value)}
        />
      )}
      {(isNational || isSection) && (
        <>
          <Button level="tertiary" onClick={() => setMoreFiltersVisible(true)}>
            {t('more filters')}
          </Button>
          <LeaguesMoreFilters
            isOpen={moreFiltersVisible}
            setIsOpen={setMoreFiltersVisible}
            initialDistrict={district}
            initialSection={section}
            onSubmit={(section, district) => {
              setSection(section)
              setDistrict(district)
            }}
          />
        </>
      )}
    </Grid>
  )
}

export default LeaguesFilters
