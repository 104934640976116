import React, { FC } from 'react'
import AdminTable, { DataCols } from '../../admin-table/admin-table'
import { GetAreaLeagues_leagues_items } from '../../../graphql-types/GetAreaLeagues'
import { Grid } from '@material-ui/core'
import { Avatar } from '@clubspark-react/ui'
import moment from 'moment'
import { LeaguesStatus } from '../../areas/area-helpers'
import { useTranslation } from 'react-i18next'
import { ControlProps } from '@clubspark-react/clubspark-react-tools'

type LeaguesTableProps = {
  leagues: GetAreaLeagues_leagues_items[] | undefined
  controlProps: ControlProps
  loading: boolean
}
const LeaguesTable: FC<LeaguesTableProps> = ({ controlProps, loading, leagues }) => {
  const { t } = useTranslation()

  const columns: DataCols<GetAreaLeagues_leagues_items> = [
    {
      key: 'league',
      getValue: item => (
        <Grid container spacing={2} alignItems={'flex-end'}>
          <Grid item>
            <Avatar radius={'none'} alt={item?.name} src={item?.logoUrl ?? ''} />
          </Grid>
          <Grid item>
            <p>{item.name ?? '-'}</p>
            <span>{item.id ?? '-'}</span>
          </Grid>
        </Grid>
      ),
      title: t('league')
    },
    {
      key: 'dates',
      getValue: item =>
        t('league dates', { start: moment(item?.startDate), end: moment(item?.endDate) }),
      title: t('dates')
    },
    {
      key: 'category',
      getValue: item => item?.category?.name ?? '-',
      title: t('category')
    },
    {
      key: 'leagueCoordinator',
      getValue: item => item?.primaryAdministrator?.displayName ?? '-',
      title: t('league coordinator')
    },
    {
      key: 'approved',
      getValue: item => <LeaguesStatus status={item?.status} />,
      title: t('approved')
    }
  ]
  return (
    <AdminTable
      hideTopPaginationInfo
      data={leagues}
      loading={loading}
      columns={columns}
      controls={controlProps}
    />
  )
}

export default LeaguesTable
