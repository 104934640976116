import graphql from 'graphql-tag'

export const GET_LEAGUES = graphql`
  query GetLeagues($pagination: comp_PaginationInput!, $filter: comp_LeaguesFilter) {
    leagues(pagination: $pagination, filter: $filter) {
      totalItems
      items {
        id
        externalId
        primaryAdministrator {
          displayName
        }
        name
        logoUrl
        status
        startDate
        endDate
        category {
          name
        }
      }
    }
  }
`
export const GET_LEAGUES_COORDINATORS = graphql`
  query GetLeaguesCoordinators($filter: FilterResourceInput, $pageArgs: PaginationArgs) {
    resourcesByEntityId(filter: $filter, pageArgs: $pageArgs) {
      total
      results {
        entityType
        entityId
        userId
        person {
          standardGivenName
          standardFamilyName
          email
          extensions {
            name
            value
          }
        }
        roles {
          state
          role
        }
        organisation {
          organisationName
          ownerId
          parentOrganisation {
            organisationName
          }
        }
      }
    }
  }
`
